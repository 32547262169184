#root-home {

  .logo-cloud {
    text-align: center;
    padding: 40px 0;
  }
  
  .logo-cloud img {
    max-width: 80%;
    height: auto;
    max-height: 120px; /* Contrôle la hauteur maximale des logos */
    margin: 10px;
    transition: transform 0.2s ease-in-out;
  }
  
  .logo-cloud img:hover {
    transform: scale(1.1); /* Effet au survol */
  }


  .header-contain {
    border-radius: 30px;
    --smooth-corners: 10;
    background-color: rgba(227, 238, 255, 0.40);
    box-shadow: 0px 0px 94px 0px #D6E6FF inset;
    display: block;

    .header-titleblock {
      padding: 40px;
    }
  }

  .title-section {
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 8px;
  }

  .keyvisual {
    width: 100%;
  }

  .solutions-contain {
    padding: 20px;

    .solutions-style {
      border-radius: 20px;
      background: white;
      box-shadow: 0px 8px 20px 0px rgba(0, 63, 158, 0.10);
      padding: 20px;
    }
  }

  @media (max-width: 768px) {

    .reverse {
      flex-direction: column-reverse;
    }
  }
}