#reporting-advertising {
  .add-filter {
    right: 0px;
    bottom: -5px;
    position: absolute;

    button {
      width: 14px;
    }
  }

  .attempts-percent {
    font-size: 10px;
    color: #666;
  }
}
