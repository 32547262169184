.c-campaign-countries {

}

.country-select {
  min-width: 512px;
}

.country-search {
  max-width: 512px;
}