#reporting-ssp {
  .add-filter {
    right: 0px;
    bottom: -5px;
    position: absolute;

    button {
      width: 14px;
    }
  }

  .cell-with-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cell-info {
    font-size: 10px;
    color: #666;
  }

  .control-row {
    background-color: #add2ff;

    .ant-table-column-sort {
      background-color: #add2ff;
    }
  }
}
