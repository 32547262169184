.review-popup {
  
}

.modalTitle {
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    border-radius: 10px;
    width: 32px;
  }
}