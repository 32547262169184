.search-component {
    max-width: 80%;
    margin: 0 auto;
    padding: 20px;
}

.response-section {
    margin-top: 20px;
}

.code-snippets {
    margin-top: 20px;
}
