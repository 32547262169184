
.campaign-add-component {
    max-width: 80%;
    margin: 0 auto;
    padding: 20px;
}

.response-section {
    margin-top: 20px;
}

.code-snippets {
    margin-top: 20px;
}

.block {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
}
