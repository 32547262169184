#ua-campaign {
  .videos,
  .endcards {
    display: flex;
    flex-flow: row wrap;
    > div {
      margin-right: 20px;
    }
  }

  .ant-tabs {
    border: 1px solid #f0f0f0;
    padding: 0 15px;
  }

  .error-tracking {
    color: red;
  }

  pre {
    background-color: #eeeeee;
    padding: 8px;
    border-radius: 2px;
  }

  .mmps {
    padding: 20px;
    background-color: #f6f6f6;
    margin-bottom: 20px;
    border-radius: 6px;
  }

  .submit-wrapper {
    width: 100%;
    position: fixed;
    height: 96px;
    background-color: rgba($color: #FFFFFF, $alpha: 0.8);
    bottom: 0px;
    right: 0px;
  }

  .submit {
    position: fixed;
    bottom: 32px;
    right: calc(0.75rem + 16px); // bootstrap default container gutter = 0.75rem
  }

  .ant-card {
    margin-bottom: 24px;
  }
}
