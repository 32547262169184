.review-assets {
  max-height: 580px;
  overflow: auto;

  .assets {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }
}