.code-viewer {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #f5f5f5;
  padding: 10px;
  position: relative;
}

.code-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.language-label {
  font-size: 14px;
  color: #555;
}

.copy-button {
  background-color: #f0f0f0;
  border: none;
  box-shadow: none;
}

.copy-button:hover {
  background-color: #e6e6e6;
}

.code-content {
  font-family: 'Courier New', Courier, monospace;
  font-size: 13px;
  white-space: pre-wrap;
  overflow-x: auto;
  max-height: 300px; /* Adjust height as needed */
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

code {
  white-space: pre;
}
