.c-campaign-videos {
  .c-video {
    max-height: 100px;
    max-width: 320px;
    width: 100%;
    text-align: center;
    background-color: rgb(0,0,0);

    .video {
      height: 100px;
      width: 100%;
    }

    video::-webkit-media-controls-panel {
      background: linear-gradient(0deg, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.25) 35%, rgba(0,0,0,0) 100%);
    }
  }
}