#root-about {

  .us {
    border-radius: 20px;
    --smooth-corners: 10;
    background: white;
    box-shadow: 0px 8px 20px 0px rgba(0, 63, 158, 0.1);
    padding: 20px;

    .brands-logo {
      border-radius: 30px;
      --smooth-corners: 10;
      background-color: rgba(227, 238, 255, 0.4);
      box-shadow: 0px 0px 94px 0px #D6E6FF inset;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;

      img{
        height: 60px;
        max-height: 60px;
        width: auto;
      }

      .ico-plus {
        opacity: 0.5;
        height: 40px;
      }
    }
  }

  .description {
    text-align: center;
    margin: auto 0;

    p{
      // text-align: justify;
      font-size: 18px;
    }
  }
}