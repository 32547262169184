.c-query {
  pre {
    position: relative;
    padding: 10px;
    margin-bottom: 4px;
    background-color: #dddddd;
  }
  pre:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  pre:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  pre:nth-child(2n) {
    background-color: #f6f6f6;
  }
  .dtextarea {
    position: relative;
    margin-top: 8px;
  }
  textarea {
    width: 100%;
    resize: none;
    height: 300px;
  }
  .plabel {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #777777;
    color: white;
    font-size: 12px;
    padding: 0 4px;
  }
  .pinput {
    padding: 0;
    width: 70px; 
    height: 24px;
  }
}
