.contact-us-block {
  border-radius: 20px;
  background-color: rgba(227, 238, 255, 0.2);
  box-shadow: 0px 0px 94px 0px #D6E6FF inset;
  cursor: pointer;
  color: rgb(53, 71, 92);
  padding: 20px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(227, 238, 255, 0.9);
    box-shadow: 0px 0px 94px 0px #D6E6FF inset;
    transition: all 0.2s ease-in-out;
  }

  .ico {
      height: 32px;
  }

  .content-contact {

    padding: 40px;
  }

  .keyvisual {
    height: 302px;
  }
}