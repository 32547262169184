.root-sign-up {

        .block-auth {
            margin: auto;
            a {
              margin-left: 0 !important;
            }
          }
    
      .menu-horizontal {
          display: none;
        }
    
      .login-contain {
          border-radius: 30px;
          --smooth-corners: 10;
          background-color:rgba(227, 238, 255, 0.40);
          box-shadow: 0px 0px 94px 0px #D6E6FF inset;
          display: block;
          max-width: 760px;
          margin:0 auto 0;
          margin-top:100px;
          position: relative;
          z-index: 2;
      
          .login-titleblock {
              padding: 60px;
          }
        }
    
        .ant-input {
          padding: 12px;
          transition: all 0.3s;
          border-radius: 10px;
          background: #FFF;
          box-shadow: 0px 8px 20px 0px rgba(0, 63, 158, 0.10);
      }
    
      footer {
          margin-left: -20px;
          margin-right: -20px;
          margin-bottom: -20px;
    
          .bkg-footer {
              background: linear-gradient(180deg, rgba(227, 238, 255, 0.00) 0%, rgba(170, 204, 255, 0.50) 100%);
              height: 438px;
              width: 100%;
              position: fixed;
              bottom: 0;
          }
      }
    
    
      @media (max-width: 768px) {
    
        .login-contain {
            margin-top:60px;
          }
    
          footer {
            .bkg-footer {
                background: linear-gradient(180deg, rgba(227, 238, 255, 0.00) 0%, rgba(170, 204, 255, 0.30) 100%);
            }
        }
      } 
    }