.header-title {
  border-radius: 30px;
  --smooth-corners: 10;
  background-color:rgba(227, 238, 255, 0.40);
  box-shadow: 0px 0px 94px 0px #D6E6FF inset;
  display: block;

  .header-titleblock {
    padding: 40px;
  }
}