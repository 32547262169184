.root-profile {
  .ant-card {
    margin-bottom: 20px;
  }

  .profile-style {
    height: 42px;
    margin-right: 16px;
    margin-bottom: 16px;
  }

  .keys {
    .key {
      position: relative;
      overflow: hidden;
      border: 1px solid #dddddd;
      padding: 10px;
      margin-bottom: 20px;
      background-color: #ffffff;
      border-radius: 4px;
      &.inactive {
        background-color: #fab1a0;
      }
      .new {
        position: absolute;
        color: white;
        font-weight: bold;
        top: 0;
        right: 0;
        background-color: orange;
        padding: 0 4px;
        border-left: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        border-bottom-left-radius: 4px;
        .remove {
          position: absolute;
          color: red;
          font-size: 14px;
          z-index: 1;
        }
      }
      .remove {
        position: absolute;
        font-weight: bold;
        top: 0;
        right: 4px;
        color: red;
        font-size: 14px;
      }
    }
    .ant-row.ant-form-item {
      margin-bottom: 10px;
    }
  }
}
