#contact-us {
  .contact-form {
    width: 100%;
    max-width: 600px;

    .ant-input, .ant-select {
      padding: 12px;
      transition: all 0.3s;
      border-radius: 10px;
      background: #FFF;
      box-shadow: 0px 8px 20px 0px rgba(0, 63, 158, 0.10);
      border: none;
      outline: none;
    }

    .ant-input-affix-wrapper, .ant-select-selector {
      border: none;
      outline: none;
    }
  }

  .credits {
    text-align: center;
    display: block;
  }

  .btn-primary-network {
    text-align: right !important;
  }
}
