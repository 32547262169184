#reporting-bidding {
  .add-filter {
    right: 0px;
    bottom: -5px;
    position: absolute;

    button {
      width: 14px;
    }
  }
}
