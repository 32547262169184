@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  background-color: #FDFEFF;
  font-family: 'Poppins', sans-serif !important;
  color:#35475C;
}

h1 {
  font-size: 32px !important;
  font-weight: 700 !important;
  color: rgb(53, 71, 92) !important;
}

h2 {
  font-size: 24px !important;
  font-weight: 400 !important;
  color: rgb(53, 71, 92) !important;
}

h3 {
  font-size: 16px;
  font-weight: 600;
}

p {
  font-size: 14px;
}

a {
  text-decoration: none;
  cursor: pointer;
  font-family: 'Poppins', sans-serif !important;
  text-decoration-line: none !important;
}


.btn-primaryfat-network {
  border-radius: 40px;
  color: white;
  background: linear-gradient(199deg, #FF825A -17.87%, #FF00B8 112.96%);
  box-shadow: 0px 8px 20px 0px rgba(0, 63, 158, 0.10);
  display: flex;
  height: 50px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  border:none;

  &:hover {
    background: linear-gradient(23deg, #FF825A -17.87%, #FF00B8 112.96%);
    color: white;
  }
}

.link-withicon {
  display: block;
  position: relative;
  padding-right: 20px;
  .anticon.lw-icon {
    position: absolute;
    opacity: 0.2;
    right: 0;
    top: calc(50% - 9px);
    font-size: 18px;
    vertical-align: text-bottom;
    margin-left: 8px;
  }

  &.lw-off {
    .anticon.lw-icon {
      opacity: 0;
    }
  }

  &:hover {
    .anticon.lw-icon {
      display: inline-block;
      opacity: 1;
    }
  }
}

.btn-primary-network {
  border-radius: 40px;
  color: white;
  background: linear-gradient(199deg, #FF825A -17.87%, #FF00B8 112.96%);
  box-shadow: 0px 8px 20px 0px rgba(0, 63, 158, 0.10);
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  border:none;
  height: 50px;
  width: fit-content;
  font-weight: 500;

    &:hover {
    background: linear-gradient(23deg, #FF825A -17.87%, #FF00B8 112.96%);
    color: white;
  }
}

.btn-secondary-network {
  border-radius: 40px;
  color: white;
  background: #35475C;
  box-shadow: 0px 8px 20px 0px rgba(0, 63, 158, 0.10);
  padding: 20px;
  justify-content: center;
  align-items: center;
  border:none;
  height: 50px;
  width: fit-content;
  font-weight: 500;

  &:hover {
    background: #35475ce1;
    color: white;
  }
}

.btn-tertiary-network {
  border-radius: 40px;
  color: #35475C;
  background: none;
  box-shadow: none;
  display: flex;
  height: 50px;
  padding: 10px;
  width: fit-content;
  font-weight: 500;
  justify-content: left;
  align-items: center;
  border:none;
}

.account-content {
  display: flex;
  height: 50px;
  justify-content: right;
  align-items: center;

  .account-btn {
    padding-left: 10px;
    color: #ff4693;
    font-weight: 600;
  }
}

.network-card {
  border-radius: 20px;
  background: white;
  box-shadow: 0px 8px 20px 0px rgba(0, 63, 158, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif !important;
}

img, svg {
  vertical-align: initial;
}

#app {
  &.contain {
    width: 100%;
  }

  .ant-form-vertical .ant-form-item-label {
    padding-bottom: 4px;
  }
  
  .float-left {
    float: left;
  }
  
  > .content {
    padding: 98px 20px 20px 20px;
    min-height: 100vh;
  }
}

.ant-card {
  background-color: #F4F8FF;
  border-radius: 20px;
  padding: 8px;
  .ant-card-head {
    background-color: #6395FA;
    color: white;
    border-radius: 12px;
  }
}

.ant-card-bordered {
  border: 1px solid #dfe4ef;
}

.ant-collapse {
  background-color: #f4f8ff;
  border: 1px solid #dfe4ef;
  border-bottom: 0;
  border-radius: 20px;
}

.ant-collapse-content {
  background-color: #fff;
  border-top: 1px solid #dfe4ef;
}

.ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0 0 20px 20px;
}

.block-bt {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.columns-superadmin {
  background-color: red !important;
  color: white !important;
}

@media (max-width: 768px) {

  .btn-tertiary-network {
    justify-content: center;
    align-items: center;

  }
  
  .account-content {
    justify-content: center;
    align-items: center;
  }

}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
