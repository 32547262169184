#publishing-placements {

  .search-bar {
    width: 100%;
  }

  .filters {
    margin-bottom: 16px;
    margin-top: 8px;
  }
}
