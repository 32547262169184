#root-ads-formats {
  .format {
    .informations {

      .title {
        text-align: center;
      }

      .description {
        text-align: center;
      }
    }

    .phone {
      width: 360px;
      height: 640px;
      border-radius: 40px;
      border: #D6E6FF 5px solid;
      padding: 10px;

      .board {
        background-color: #D6E6FF;
        border-radius: 30px;
        height: 100%;
        width: 100%;
        position: relative;

        .ad {
          background: linear-gradient(199deg, #FF825A -17.87%, #FF00B8 112.96%);

          &.banner {
            border-radius: 0 0 30px 30px;
            width: 100%;
            height: 14%;
            position: absolute;
            bottom: 0;
          }

          &.interstitial,
          &.reward {
            border-radius: 30px;
            width: 100%;
            height: 100%;

            .cross,
            .chrono {
              position: absolute;
              width: 30px;
              right: 10px;
              top: 10px;
              color: white;
              font-weight: bold;
            }

            .chrono {
              border: 3px solid transparent;
              border-radius: 50%;
              border-top: 3px solid white;
              border-left: 3px solid white;
              border-bottom: 3px solid white;
              width: 30px;
              height: 30px;
              text-align: center;
            }
          }

          &.reward {
            background: linear-gradient(199deg, #FF00B8 -17.87%, #FF825A 112.96%);
          }
        }
      }
    }

    .fake {
      &.full {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 30px;
      }

      &.banner {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 10%;
        gap: 10px;
      }

      .icon {
        width: 100px;
        height: 100px;
        background-color: white;
        border-radius: 30px;
        margin-bottom: 40px;
      }

      .title {
        width: 180px;
        height: 25px;
        background-color: gainsboro;
        border-radius: 30px;
      }

      .description {
        width: 110px;
        height: 25px;
        background-color: gainsboro;
        border-radius: 30px;
      }

      .button {
        width: 120px;
        height: 40px;
        background-color: white;
        border-radius: 30px;
        margin-top: 40px;
      }
    }
  }

}