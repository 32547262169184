.yc-card {
  .pointerCursor {
    cursor: pointer;
  }
  .ant-card-head {
    min-height: 0;
    padding: 0;
    .ant-card-head-title {
      padding: 0;
      .yc-title {
        padding: 10px 20px;
      }
    }
  }
  &.close:not(:hover) {
    .ant-card-head {
border-radius: 12px;
    }
  }


  &.close {
    .ant-card-body {
      padding: 0;
      border-radius: 20px;
    }
  }
}