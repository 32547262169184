.c-campaign-assets {
  .asset {
    max-height: 100px;
    max-width: 320px;
    width: 320px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}