.block-validation {
  height: 300px;
  width: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 10px rgba(0, 0 , 0, 0.2);

}

.e-banner {
  max-width: 100%;
  max-height: 100%;
}

.e-image,
.e-playable,
.e-endcard,
.video {
  max-width: 100%;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
}