.c-loading {
  position: relative;
  .c-loading-over {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    opacity: 0.5;  
  }
}
