#admin-assets {
  .asset {
    .image, .video, .endcard {
      max-width: 160px;
      max-height: 160px;
    }
    .banner {
      max-width: 360px;
    }
  }
}