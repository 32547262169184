.footer {

  background-color: #f4f8ff;
  width: 100%;
  padding: 40px;
  border-radius: 20px;

  .credits {
    text-align: center;
    font-weight: 600;
  }

  a {

    color: #35475C;

    &:hover {
      color: #419aff;
    }
  }
}