#upload-avatar {
  .ant-upload.ant-upload-select-picture-card {
    overflow: hidden;
  }

.ant-upload.ant-upload-select-picture-card {
  width: 128px;
  height: 128px;
  margin-right: 12px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border-radius: 20px;
  border: 1px solid rgb(201, 206, 222);
  cursor: pointer;
  transition: border-color 0.3s;
}
}