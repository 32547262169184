.c-playable {
  max-width: 320px;
  width: 100%;
  text-align: center;
  background-color: rgb(0,0,0);

  &.big-preview {
    height: 480px;
  }

  .fullscreen-button {
    position: absolute;
    margin-left: -30px;
    margin-top: 6px;
    background-color: rgba(255,255,255, 0.35);

    &.is-fullscreen {
      position: fixed;
      top: 15px;
      right: 20px;
      z-index: 1001;
    }
  }

  .iframe {
    width: 100%;

    &.big-preview {
      height: 480px;
    }

    &.is-fullscreen {
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1000;
    }
  }
}
