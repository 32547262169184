#docs {
  .doc-container {
    margin-top: 10px;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    padding: 0px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after {
    left: -10px;
    right: -10px;
  }
}