#header-component {
  left: 0;
  position: fixed;
  z-index: 100;
  width: 100%;
  background-color: #FDFEFF;
  justify-content: space-between;
  padding: 0;


  .container {
    width: 100%;
    max-width: 100%;

    .ant-menu {
      border: none!important;
    }

    .menu-horizontal {
      width: calc(100% - 150px);
      .ant-menu {
        justify-content: center;
        margin-right: 150px;
      }
      @media screen and (max-width: 1124px) {
        width: calc(100% - 100px);
        .ant-menu {
          justify-content: center;
          margin-right: 150px;
        }
      }
    }

    img {
      width: 150px;
      padding: 10px 0;

      @media screen and (max-width: 1124px) {
        width: 100px;
      }
    }

    li {
      font-weight: 400;
      font-size: 16px;
      padding: 10px 20px;

      // &:hover {
      //   background: linear-gradient(5deg, #FF00B8 15%, #FF825A 60%);
      //   -webkit-background-clip: text;
      //   -moz-background-clip: text;
      //   -webkit-text-fill-color: transparent;
      //   -moz-text-fill-color: transparent;

      //   &::after {
      //     border-image-slice: 1;
      //     border-image-source: linear-gradient(6deg, #FF00B8 10%, #FF825A 60%);
      //   }
      // }

      @media screen and (min-width: 576px) and (max-width: 1124px) {
        font-size: 12px;
      }
    }

    small {
      display: block;
      font-weight: normal;
      font-size: 10px;
      line-height: 10px;
    }

    hr {
      margin: 8px 10px;
    }
  }
}
.header-link {
  position: relative;

  .cap-badge {
    position: absolute;
    right: -4px;
    top: -4px;

    .ant-badge-dot {
      height: 10px;
      width: 10px;
    }
  }
}
