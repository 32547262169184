#review-install {
  margin-top: 20px;
  padding: 0% 20%;
  .content {
    display: flex;
    justify-content: space-around;

    li {
      list-style-type: decimal;
      margin-bottom: 5px;
    }

    .validation-button {
      display: flex;
      gap: 5px;
    }

    .validation-text {
      border: solid 1px;
      padding: 0 5px;

      &.valid {
        color: green;
        border-color: green;
      }

      &.unvalid {
        color: red;
        border-color: red;
      }
    }
  }
}