#admin-control-panel {
  .meta-card {
    width: 100%;
    padding: 16px;
    border-radius: 16px;
    background: #F4F8FF;
    border: 1px solid #DFE4EF;
    margin-bottom: 16px;
  }

  .meta-card-title {
    margin-bottom: 16px;

    color: #35475C;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .meta-card-content {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  .meta-card-graph {
    flex: 1 1 100%;
    padding: 8px;

    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: 0 4px 20px 0 #003F9E1A;
  }

  .meta-card-graph-title {
    margin-bottom: 8px;
    color: #35475C;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .meta-card-graph-diffs-container {
    display: flex;
    gap: 20px;
  }

  .meta-card-graph-diffs {
    display: block;
    min-width: calc(50% - 20px);
  }

  .meta-card-graph-diffs-title {
    color: #35475C;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  .meta-card-graph-diffs-content {
    display: flex;
    gap: 8px;
  }

  .meta-card-graph-diff {
    flex: 1 1 calc(50% - 8px);
    max-width: calc(50% - 8px);
    border-radius: 8px;
    border: 1px solid #DFE4EF;
    text-align: center;

    &.--red {
      background: rgba(219, 0, 0, 0.05);
      color: #DB0000;
    }

    &.--orange {
      background: rgba(255, 144, 0, 0.10);
      color: #E78300;
    }

    &.--green {
      background: rgba(0, 128, 0, 0.05);
      color: #008000;
    }
  }

  .meta-card-graph-diff-label {
    color: #35475C;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .meta-card-graph-diff-percent {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    .caret {
      font-size: 10px;
    }
  }

  .meta-card-graph-diff-numbers {
    color: #35475C;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  /* For screens wider than 1200px */
  @media (min-width: 1200px) {
    .meta-card-graph {
      flex: 1 1 calc(33.33% - 16px);
      max-width: calc(33.33% - 16px);

      &.--double {
        flex: 1 1 calc(50% - 16px);
        max-width: calc(50% - 16px);
      }

      &.--simple {
        flex: 1 1 100%;
        max-width: 100%;
      }
    }
  }
}
