#admin-asset {
  .block-content {
    display: flex;
    height: 300px;
    flex-direction: column;
    align-items: center;
  }
  .e-banner {
    max-width: 100%;
    max-height: 100%;
  }
  .e-image, .e-playable, .e-endcard, .video {
    max-width: 100%;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
  }
}
