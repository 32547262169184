#ua-campaigns {
  .mmp-warning {
    vertical-align: 0;
  }

  .search-bar {
    width: 100%;
  }

  .filters {
    margin-bottom: 16px;
    margin-top: 8px;
  }

  .check-boxes {
    margin-top: 5px;
    margin-bottom: 5px;
  }

}
