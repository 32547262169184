.c-icon {
  width: 34px;
  height: 34px;
  border-radius: 25%;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  .dicon {
    width: 100%;
    height: 100%;
    background-color: #eeeeee;
  }
}
