.json-viewer {
  border-radius: 4px;
  color: #fff;
  overflow-x: auto;
}

.no-data {
  color: #999;
  font-size: 14px;
  text-align: center;
}
